import React, { useEffect, useState } from "react";
import { Container, Button, Modal, Form } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCustormers, updateCustormerData, createNewCustormer } from "../redux/actions/CustormerAction";
import { fetchAllUserRoles, fetchAllUsersRegisterd, saveNewUser, updateUserByUserId } from "../redux/actions/userAction";
import { MultiSelect } from "primereact/multiselect";
import CreatableSelect from "react-select/creatable";

function UsersManagement() {
  const dispatch = useDispatch();

  // Redux selectors
  const { users = [], loading: userLoading } = useSelector((state) => state.users || {});
  const { userRoles = [], loading: userRolesLoading } = useSelector((state) => state.userRoles || {});
  const { customers = [], loading: customersLoading } = useSelector((state) => state.customers || {});

  // Local states
  const [formattedUserRoles, setFormattedUserRoles] = useState([]);
  const [updatedUserListWithRoleName, setUpdatedUserListWithRoleName] = useState([]);
  const [companies, setCompanies] = useState();
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    address: "",
    phone_number: "",
    user_role: "",
    company_registration_id: [],
  });

  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch data
  useEffect(() => {
    dispatch(fetchAllUsersRegisterd());
    dispatch(fetchAllUserRoles());
    dispatch(fetchAllCustormers());
  }, [dispatch]);


  // Format dropdown and table data
  useEffect(() => {
    if (users.length && userRoles.length && customers.length) {
      formatAllDataForTablesAndDropDowns();
    }
  }, [users, userRoles, customers]);

  const formatAllDataForTablesAndDropDowns = () => {
    const userCompanies = [];
    // Format user roles
    const formattedRoles = userRoles.map((role) => ({
      value: role.id,
      label: role.name,
      description: role.description,
    }));
    setFormattedUserRoles(formattedRoles);

    const updatedUsers = users.map((user) => ({
      ...user,
      user_role: formattedRoles.find((role) => role.value === user.user_role)?.label || "Unknown Role",
      companies: user.company_registration_id.map((comId) => {
        const company = customers.find((company) => company.id === comId);
        return company ? company.company_name : null;
      }).filter(Boolean)
        .join(", ")
    }));

    setUpdatedUserListWithRoleName(updatedUsers);


    // Format companies for dropdown
    const formattedCompanies = customers.map((company) => ({
      value: company.id,
      label: company.company_name,
    }));
    setCompanies(formattedCompanies);
  };

  // Show modal for add/edit
  const handleShowModal = (user = null) => {
    setSelectedUser(user);
    setFormData(
      user || {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        address: "",
        phone_number: "",
        user_role: "",
        company_registration_id: [],
      }
    );
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
    setFormData({
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      address: "",
      phone_number: "",
      user_role: "",
      company_registration_id: [],
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (selectedUser) {
      if (formData.password !== formData.confirmPassword) {
        alert("Passwords do not match!");
        return;
      }
    }

    if (selectedUser) {

      const newFormData = {
        ...formData,
        company_registration_id: selectedCompanies,
      }
      dispatch(updateUserByUserId(selectedUser.id, newFormData));
    } else {
      const newFormData = {
        ...formData,
        company_registration_id: selectedCompanies,
      }
      console.log('request', newFormData);
      dispatch(saveNewUser(newFormData));
    }

    handleCloseModal();
  };

  const handleDeleteUser = () => {
    if (selectedUser) {
      alert(`Deleted user: ${selectedUser.username}`);
      setSelectedUser(null);
    } else {
      alert("Please select a user to delete.");
    }
  };

  // Conditional rendering for table and modal
  if (userLoading || userRolesLoading || customersLoading) {
    return <div>Loading...</div>;
  }

  const handleSelect = (e) => {
    if (e) {
      setSelectedUser(e.value);
      setSelectedCompanies(e.value.company_registration_id);
    }
  }


  return (
    <Container className="mt-4">
      <h2 className="mb-4 text-center">Manage Users</h2>

      <div className="mb-3">
        <Button variant="success" onClick={() => handleShowModal()}>
          Add User
        </Button>
        <Button
          variant="primary"
          className="mx-2"
          onClick={() => (selectedUser ? handleShowModal(selectedUser) : alert("Please select a user to edit."))}
        >
          Edit User
        </Button>
        <Button variant="danger" onClick={handleDeleteUser}>
          Delete User
        </Button>
      </div>

      <div className="card">
        <DataTable
          value={updatedUserListWithRoleName}
          selectionMode="single"
          selection={selectedUser}
          onSelectionChange={handleSelect}
          dataKey="id"
          metaKeySelection={false}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="id" header="ID"></Column>
          <Column field="first_name" header="First Name"></Column>
          <Column field="last_name" header="Last Name"></Column>
          <Column field="username" header="Username"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="phone_number" header="Phone Number"></Column>
          <Column field="address" header="Address"></Column>
          <Column field="user_role" header="User Role"></Column>
          <Column field="companies" header="Companies"></Column>
        </DataTable>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Form onSubmit={handleFormSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedUser ? "Edit User" : "Add User"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                placeholder="Enter first name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                placeholder="Enter last name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                placeholder="Enter username"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Enter address"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                placeholder="Enter phone number"
              />
            </Form.Group>
            {
              selectedUser ? (<>


              </>) : (<>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Enter password"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    placeholder="Confirm password"
                  />
                </Form.Group>
              </>)
            }

            <Form.Group className="mb-3">
              <Form.Label>Company</Form.Label>
              {companies && companies.length > 0 ? (
                <CreatableSelect
                  isMulti
                  value={selectedCompanies.map((companyId) => ({
                    value: companyId,
                    label: companies.find((c) => c.value === companyId)?.label || ''
                  }))}
                  options={companies}
                  onChange={(selectedOptions) => {
                    // Get only the IDs of selected companies
                    const selectedIds = selectedOptions.map(option => option.value);
                    setSelectedCompanies(selectedIds);
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  isClearable
                  placeholder="Select companies"
                />
              ) : (
                <p>Loading companies...</p> // Or any fallback UI you prefer
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <CreatableSelect
                isClearable
                options={formattedUserRoles}
                value={formattedUserRoles.find((opt) => opt.value === formData.user_role) || null} // Corrected line
                onChange={(option) =>
                  handleInputChange({ target: { name: "user_role", value: option ? option.value : "" } })
                }
              />
            </Form.Group>

          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="success" type="submit">
              {selectedUser ? "Update User" : "Save User"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
}

export default UsersManagement;
