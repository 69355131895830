import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import 'primereact/resources/themes/saga-blue/theme.css'; // Theme
import 'primereact/resources/primereact.min.css'; // Core CSS
import 'bootstrap/dist/css/bootstrap.min.css'; // React-Bootstrap CSS
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUserRoles, updateUserByUserId } from '../redux/actions/userAction';

function UserRoleManagement() {
    const dispatch = useDispatch();
    const [roles, setRoles] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [currentRole, setCurrentRole] = useState({ name: '', description: '' });
    const { userRoles, loading: userRolesLoading } = useSelector((state) => state.userRoles);

    useEffect(() => {
        dispatch(fetchAllUserRoles());
    }, [dispatch])

    useEffect(() => {
        if (userRoles.length) {
            setRoles(userRoles);
        }
    }, [userRoles])

    const openModal = (role = { id: null, name: '', description: '' }) => {
        setCurrentRole(role);
        setShowModal(true);
    };

    const closeModal = () => setShowModal(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentRole({ ...currentRole, [name]: value });
    };

    const handleSave = () => {
        if (currentRole.id) {
            dispatch(updateUserByUserId(currentRole.id,currentRole))
        } else {
            
        }
        closeModal();
    };

    const handleDelete = (id) => {
        setRoles(roles.filter((role) => role.id !== id));
    };

    const actionBodyTemplate = (rowData) => (
        <>
            <Button variant="warning" className="me-2" onClick={() => openModal(rowData)}>
                <FaEdit /> Edit
            </Button>
            <Button variant="danger" onClick={() => handleDelete(rowData.id)}>
                <FaTrash /> Delete
            </Button>
        </>
    );

    return (
        <div className="container mt-4">
            <h2>User Role Management</h2>
            <Button variant="primary" className="mb-3" onClick={() => openModal()}>
                <FaPlus /> Add New Role
            </Button>
            <DataTable value={roles} paginator rows={5} responsiveLayout="scroll">
                <Column field="id" header="ID" />
                <Column field="name" header="Name" />
                <Column field="description" header="Description" />
                <Column header="Actions" body={actionBodyTemplate} />
            </DataTable>

            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentRole.id ? 'Edit Role' : 'Add Role'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={currentRole.name}
                                onChange={handleInputChange}
                                placeholder="Enter role name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={currentRole.description}
                                onChange={handleInputChange}
                                placeholder="Enter role description"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UserRoleManagement;
