import React, { useState } from "react";
import { Container, Form, Button, Table, Dropdown, Card, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

function ResourceGroupManagement() {
  const [selectedGroup, setSelectedGroup] = useState("Select Center Group");
  const [centers, setCenters] = useState([]);
  const [formData, setFormData] = useState({
    centerName: "",
    farmerName: "",
    phone: "",
    email: "",
    category: "",
    subcategory: "",
    location: "",
    yield: "",
    storageFacility: false,
    description: "",
  });

  const { groupId } = useParams();
  const imageUrl = "https://www.wbcsd.org/wp-content/uploads/2023/11/Farmers-stand-to-see-increase-crop-yields-and-profits-with-15-25-return-on-investment-by-transitioning-to-regenerative-farming-practices_i1140-jpg.webp"



  const formFields = [
    { name: "centerName", type: "text", placeholder: "Center Name", label: "Center Name" },
    { name: "category", type: "text", placeholder: "Category (e.g., Fruits)", label: "Category" },
    { name: "subcategory", type: "text", placeholder: "Subcategory (e.g., Citrus)", label: "Subcategory" },
    { name: "location", type: "text", placeholder: "Location", label: "Location" },
    { name: "yield", type: "number", placeholder: "Expected Yield (kg)", label: "Expected Yield (kg)" },
    { name: "description", type: "textarea", placeholder: "Additional Details", label: "Additional Details" },
    { name: "image", type: "file", placeholder: "upload image", label: "Image" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddCenter = (e) => {
    e.preventDefault();
    setCenters([...centers, { ...formData, group: groupId }]);
    setFormData({
      centerName: "",
      category: "",
      subcategory: "",
      location: "",
      yield: "",
      storageFacility: false,
      description: "",
    });

  };

  return (
    <>
      {/* Hero Section */}
      <div
        style={{
          position: "relative",
          backgroundImage: `url('https://cdn-01.cms-ap-v2i.applyflow.com/agworkforce/wp-content/uploads/2023/01/Cereal-Farming-Body-of-page-min.jpg')`, // Replace with your image URL
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "300px",
        }}
      >
        {/* Dark Overlay */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)", // Adjust opacity as needed
            zIndex: 1,
          }}
        ></div>

        {/* Hero Content */}
        <div
          style={{
            position: "relative",
            zIndex: 2,
            color: "white",
            textAlign: "center",
            padding: "100px 20px",
          }}
        >
          <h1>Welcome to Center Group Management</h1>
          <p>Efficiently manage your center groups and add detailed center information.</p>
        </div>
      </div>

      {/* Main Content */}
      <Container className="py-4">
        <div className="my-2">
          <h2 className="text-success">Streamline Your Farm Operations with Center Groups</h2>
          <p className="lead">Efficiently manage your farm's scheduling, capacity, and resources with dedicated center groups for better collaboration and growth.</p>
        </div>


        {/* Form Section */}
        <Form onSubmit={handleAddCenter} className="my-5">
          <h2>Add Center to {selectedGroup}</h2>
          {formFields.map((field, index) => (
            <Form.Group key={index} className="mb-3" controlId={field.name}>
              <Form.Label>{field.label}</Form.Label>
              {field.type === "textarea" ? (
                <Form.Control
                  as="textarea"
                  rows={3}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                />
              ) : field.type === "file" ? (
                <Form.Control
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                />
              ) : <Form.Control
                type={field.type}
                name={field.name}
                placeholder={field.placeholder}
                value={formData[field.name]}
                onChange={handleInputChange}
              />}
            </Form.Group>
          ))}

          <Button type="submit" variant="success" >
            Add Center
          </Button>
        </Form>

        {centers.length >0 ? (<><h2 className="text-left mb-4">Your Centers</h2></>):(<></>)}

        {/* Table Section */}
        {centers.map((center, index) => (
          <Col key={index} md={4} className="mb-4">
            <Card>
              {/* Image Section */}
              <Card.Img
                variant="top"
                src={imageUrl}
                alt="Center Image"
              />
              <Card.Body>
                <Card.Title>{center.centerName}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Center Group 1</Card.Subtitle>
                <Table striped bordered hover responsive>
                  <tbody>
                    {centers.map((center, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td><strong>Center Name</strong></td>
                          <td>{center.centerName}</td>
                        </tr>
                        <tr>
                          <td><strong>Category</strong></td>
                          <td>{center.category}</td>
                        </tr>
                        <tr>
                          <td><strong>Subcategory</strong></td>
                          <td>{center.subcategory}</td>
                        </tr>
                        <tr>
                          <td><strong>Location</strong></td>
                          <td>{center.location}</td>
                        </tr>
                        <tr>
                          <td><strong>Expected Yield</strong></td>
                          <td>{center.yield} kg</td>
                        </tr>
                        <tr>
                          <td><strong>Description</strong></td>
                          <td>{center.description}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
                <Link to={'/center-operations'}><Button variant="success">More Details</Button></Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Container>
    </>
  );
}

export default ResourceGroupManagement;
