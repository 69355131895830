import React, { useEffect, useState } from "react";
import { Container, Table, Button, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createNewCustormer, fetchAllCustormers, updateCustormerData } from "../redux/actions/CustormerAction";
import { DataTable } from 'primereact';
import { Column } from 'primereact';

function CustomerManagement() {
    const dispatch = useDispatch();
    const { customers, loading } = useSelector((state) => state.customers);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        company_name: "",
        short_name: "",
        address: "",
        email: "",
        contact_number: "",
        registration_number: "",
    });
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    useEffect(() => {
        dispatch(fetchAllCustormers());
    }, [dispatch, showModal]);

    console.log(customers);


    const handleShowModal = () => {
        if (selectedCustomer) {
            setFormData({
                company_name: selectedCustomer.company_name || '',
                short_name: selectedCustomer.short_name || '',
                address: selectedCustomer.address || '',
                email: selectedCustomer.email || '',
                contact_number: selectedCustomer.contact_number || '',
                registration_number: selectedCustomer.registration_number || '',
            });
        }
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFormData({
            company_name: "",
            short_name: "",
            address: "",
            email: "",
            contact_number: "",
            registration_number: "",
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (selectedCustomer) {
            // Update existing customer
            dispatch(updateCustormerData(selectedCustomer.id, formData));
        } else {
            // Add new customer
            dispatch(createNewCustormer(formData));
        }
        handleCloseModal();
    };

    const handleDeleteRow = () => {

    }

    return (
        <Container className="mt-4">
            <h2 className="mb-4 text-center">Manage Companies</h2>
            <Button variant="success" className="mb-3" onClick={() => handleShowModal()}>
                Add Company
            </Button>
            <Button variant="primary" className="mb-3 mx-1" onClick={() => selectedCustomer ? handleShowModal() : alert('Pleace Select A Row')}>
                Edit
            </Button>
            <Button variant="danger" className="mb-3" onClick={() => handleDeleteRow()}>
                Delete
            </Button>
            <div className="card">
                <DataTable
                    value={customers}
                    selectionMode="single"
                    selection={selectedCustomer}
                    onSelectionChange={(e) => setSelectedCustomer(e.value)}
                    dataKey="id"
                    metaKeySelection={false} 
                    tableStyle={{ minWidth: '50rem' }}>
                    <Column field="company_name" header="Comapny Name"></Column>
                    <Column field="short_name" header="Short Name"></Column>
                    <Column field="registration_number" header="Company Registration Number"></Column>
                    <Column field="contact_number" header="Contact Number"></Column>
                    <Column field="email" header="Email"></Column>
                    <Column field="address" header="Address"></Column>
                </DataTable>
            </div>

            {/* Modal for Add/Edit */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Form onSubmit={handleFormSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {selectedCustomer ? "Edit Customer" : "Add Customer"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formCompanyName">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="company_name"
                                value={formData.company_name}
                                onChange={handleInputChange}
                                placeholder="Enter company name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formShortName">
                            <Form.Label>Short Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="short_name"
                                value={formData.short_name}
                                onChange={handleInputChange}
                                placeholder="Enter short name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                placeholder="Enter address"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder="Enter email"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formContactNumber">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="contact_number"
                                value={formData.contact_number}
                                onChange={handleInputChange}
                                placeholder="Enter contact number"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formRegistrationNumber">
                            <Form.Label>Registration Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="registration_number"
                                value={formData.registration_number}
                                onChange={handleInputChange}
                                placeholder="Enter registration number"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="success" type="submit">
                            {
                                selectedCustomer ? ' update' : 'save'
                            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Container>
    );
}

export default CustomerManagement;
