import React, { Children, useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Button,
    Modal,
    Form,
    Navbar,
    Nav,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // For redirection
import { fetchAllUserRoles, getUsersByRoleId, saveNewUser } from "../redux/actions/userAction";
import Usersmanagement from "./Usersmanagement";

function AdminDashboard({children}) {


    return (
        <div className="admin-dashboard">
            {/* <Navbar expand="lg" className="bg-success text-white">
                <Container>
                    <Navbar.Brand href="#home" className="text-white">Admin Dashboard</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#dashboard" className="text-white">Dashboard</Nav.Link>
                            <Nav.Link href="#users" className="text-white">Users</Nav.Link>
                            <Nav.Link href="#settings" className="text-white">Settings</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}
            <Container fluid className="mt-4">
                <Row>
                    <Col md={2} className="bg-light p-3">
                        <Nav className="flex-column">
                            <Nav.Link href="#dashboard">Dashboard</Nav.Link>
                            <Nav.Link href="/userManagement">Manage Users</Nav.Link>
                            <Nav.Link href="/userRoleManagement">UserRole Management</Nav.Link>
                            <Nav.Link href="/custormerManagement">Manage Companies</Nav.Link>
                            <Nav.Link href="#reports">Reports</Nav.Link>
                            <Nav.Link href="#settings">Settings</Nav.Link>
                        </Nav>
                    </Col>
                    <Col md={10}>
                        {children}
                    </Col>
                </Row>
            </Container>


        </div>
    );
}

export default AdminDashboard;
