import React, { useEffect, useState } from "react";
import TableViewDoc from "../DoctorApp/TableViewDoc";
import axios from "axios";
import { Navbar, Container, Button, Modal, Table, Jumbotron } from "react-bootstrap";
import { FaUserMd, FaPaperPlane, FaStethoscope, FaRecycle, FaPlayCircle, FaCopy } from "react-icons/fa";
import "./DocApp.css";
import Loder from '../component/Loder';
import { GrPowerCycle } from "react-icons/gr";

function DocApp() {
  const [docAppData, setDocAppData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messageDetails, setMessageDetails] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [groupedMessageData, setGroupedMessageData] = useState();
  const [deviceId, setDeviceId] = useState();
  const [genaratedAgoraToken, setGenaratedAgoraToken] = useState();
  const [copied, setCopied] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [generatedLink, setGeneratedLink] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/messages/show-only-today-messages/`
        );
        setDocAppData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchAllMessages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/messages/get-all-messages-according-to-device-id/${deviceId}/`
        );
        setMessageDetails(response.data);
      } catch (err) {
        alert("Failed to fetch messages for the device.");
      }
    }
    fetchAllMessages();
  }, [deviceId])

  const handleView = async (deviceId) => {
    setDeviceId(deviceId);
    setModalOpen(true);
  };

  const genarateAgoraToken = async () => {
    try {
      const payload = {
        "channel_name": "HealthDocApp",
      }

      const responce = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/generate-token-agora/`, payload);
      setGenaratedAgoraToken(responce.data.token);
      setGeneratedLink(genereateLinkForUsingAgoraToken(responce.data.token));
    } catch (error) {
    }
  }

  const genereateLinkForUsingAgoraToken = (token) => {
    const url = new URL(window.location.href);
    const hostname = url.hostname;

    return `http://${hostname}:3000/joinMeeting/token?${token}`;
  }

  const handleSendMessage = async (deviceId) => {
    if (!newMessage.trim()) {
      alert("Please enter a message.");
      return;
    }

    try {
      const payload = {
        doctor_response: newMessage,
        device_id: deviceId,
      };

      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/messages/doctor/`, payload);
      alert("Message sent successfully!");
      setNewMessage("");
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/messages/get-all-messages-according-to-device-id/${deviceId}`
      );
      setMessageDetails(response.data);
    } catch {
      alert("Failed to send the message.");
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setMessageDetails(null);
  };

  const copyGenaratedAgoraToken = () => {
    navigator.clipboard.writeText(generatedLink);
    setCopied(true);
  }

  if (loading) return <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '20%' }}><Loder /></div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section">
        <Container className="text-center">
          <h1 className="display-4">
            Welcome to Healthcare Services <FaUserMd />
          </h1>
          <p className="lead mt-3">
            Efficient and secure communication between doctors and patients for improved healthcare delivery.
          </p>
          <Button variant="light" size="lg" className="mt-4">
            Explore Messages
          </Button>
        </Container>
      </div>

      <Container className="mt-4">
        {/* Table View */}
        <h2 className="text-success mb-3">Today's Messages</h2>
        <Table bordered hover className="text-center">
          <thead className="table-success">
            <tr>
              <th>Device ID</th>
              <th>User Messages</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(docAppData).length > 0 ? (
              Object.entries(docAppData).map(([deviceId, messages]) => {
                const latestMessage = messages[0]; // Get the latest message for each device

                return (
                  <tr key={deviceId}>
                    <td>{deviceId}</td>
                    <td>{latestMessage.patient_message || latestMessage.doctor_response || "No message available"}</td>
                    <td>
                      <Button
                        variant="success"
                        onClick={() => handleView(deviceId)}
                        aria-label={`View messages for device ${deviceId}`}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>

      {/* Modal for Message Details */}
      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Message Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {messageDetails ? (
              messageDetails.map((msg, index) => (
                <div key={index} className="mb-3">
                  {msg.patient_message && (
                    <div className="d-flex justify-content-start">
                      <div
                        style={{
                          maxWidth: "70%",
                          padding: "10px",
                          borderRadius: "10px",
                          backgroundColor: 'green',
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        <p>{msg.patient_message}</p>
                      </div>
                    </div>
                  )}
                  {msg.doctor_response && (
                    <div className="d-flex justify-content-end">
                      <div
                        style={{
                          maxWidth: "70%",
                          padding: "10px",
                          borderRadius: "10px",
                          backgroundColor: "#d1e7dd",
                          textAlign: "right",
                        }}
                      >
                        <p>{msg.doctor_response}</p>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center"><Loder /></div>
            )}
          </div>
          <textarea
            className="form-control mt-3"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            rows={3}
            placeholder="Type your response here..."
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => handleSendMessage(messageDetails[0].device_id)}
          >
            <FaPaperPlane className="me-2" />
            Send Message
          </Button>
        </Modal.Footer>
        <div className='form-group text-center'>
          <input
            className='input-Box'
            type='text'
            value={generatedLink}
          />
          <FaCopy onClick={copyGenaratedAgoraToken} style={{ color: copied ? "green" : "", cursor: 'pointer' }} className="me-2" />
        </div>
        <Button
          variant="primary"
          onClick={() => genarateAgoraToken(messageDetails[0].device_id)}
        >
          <GrPowerCycle className="me-2" />
          Genarate Token
        </Button>
      </Modal>
    </div>
  );
}

export default DocApp;
