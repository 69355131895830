import {
    FETCH_CUSTORMERS_REQUEST,
    FETCH_CUSTORMERS_SUCCESS,
    CREATE_CUSTORMERS_FAILURE,
    FETCH_CUSTORMERS_FAILURE
} from "../actions/CustormerAction";

const initialState = {
    loading: false,
    customers: [],
    error: ''
}

const CustormerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CUSTORMERS_REQUEST:
            return {
                ...state,
                loading: true,
            }


        case FETCH_CUSTORMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: action.payload,
                error: ''
            }

        case FETCH_CUSTORMERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state;
    }
}

export default CustormerReducer;