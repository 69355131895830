import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();



export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: !!localStorage.getItem('AuthToken'),
    token: localStorage.getItem('AuthToken') || null
  })

  const navigate = useNavigate()

  const login = (token) => {
    localStorage.setItem('AuthToken', token);
    setAuth({ isAuthenticated: true, token });
    navigate('/');
  }

  const logout = (token) => {
    localStorage.removeItem('AuthToken');
    setAuth({ isAuthenticated: true, token });
    navigate('/');
  }

  useEffect(() => {
    
  })

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
