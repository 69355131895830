import React from 'react';
import { Chart } from 'react-google-charts';

function GrantChart({ tasks }) {

    // Check if tasks is an array before proceeding
    if (!Array.isArray(tasks)) {
        return <div>Invalid data: tasks is not an array</div>;
    }

    // If tasks is empty, return an appropriate message
    if (tasks.length === 0) {
        return <div>No tasks available</div>;
    }

    // Map tasks to the Gantt chart format
    const mappedData = tasks.map((task, index) => {
        const startDate = new Date(task.start_date);
        const endDate = new Date(task.end_date);
        const duration = (endDate - startDate) / (1000 * 60 * 60 * 24); // Calculate duration in days
        const dependencies = index === 0 ? null : tasks[index - 1].id.toString();

        return [
            task.id.toString(),                      // Task ID
            task.operation.toString(),               // Task Name
            task.operation.toString(),               // Resource (set to operation for now)
            startDate,                               // Start Date
            endDate,                                 // End Date
            duration,                                // Duration in days
            task.progress,                                       // Percent Complete (default to 0)
            dependencies,                                    // Dependencies (set to null if not available)
        ];
    });

    // Prepare the chart data
    const data = [
        [
            { type: 'string', label: 'Task ID' },
            { type: 'string', label: 'Task Name' },
            { type: 'string', label: 'Resource' },
            { type: 'date', label: 'Start Date' },
            { type: 'date', label: 'End Date' },
            { type: 'number', label: 'Duration' },
            { type: 'number', label: 'Percent Complete' },
            { type: 'string', label: 'Dependencies' },
        ],
        ...mappedData,
    ];

    // Gantt chart options with date-wise settings
    const options = {
        height: 500,
        gantt: {
            criticalPathEnabled: true,
            criticalPathStyle: {
                stroke: '#e64a19',
                strokeWidth: 10,
            },
            trackHeight: 50,
            // Configure the timeline behavior
            timeline: {
                showRowLabels: true,
                barHeight: 20,
                gridlineColor: '#eaeaea',
            },
        },
    };

    return (
        <div style={{ fontFamily: 'Arial, sans-serif' }}>
            <Chart
                chartType="Gantt"
                width="100%"
                height="500px"
                data={data}
                options={options}
            />
        </div>
    );
}

export default GrantChart;
