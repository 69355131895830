// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import itemReducer from './reducers/itemReducer';
import userReduser from './reducers/userReducer';
import ItemMetirialReducer from './reducers/ItemMetirialActionReducers';
import CustormerReducer from './reducers/CustormerReducer';

const store = configureStore({
    reducer: {
        itemsData: itemReducer,
        user: userReduser,
        itemsMetirials: ItemMetirialReducer,
        customers: CustormerReducer,
        users:userReduser,
        userRoles:userReduser
    },
});

export default store;


