import React from 'react'
import { useAuth } from './AuthProvider'
import { Navigate, Route } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const { auth } = useAuth();
  
  if (!auth.isAuthenticated) {
    return <Navigate to={"/login"} />
  }

  return element;
}

export default PrivateRoute;